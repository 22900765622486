import React from "react"
import Gimg from "gatsby-image"
import Section from "../templates/Section"

const renderTripleCard = ({ header, body, image, link, id }) => (
  <div className="triple-card" key={id}>
    <div className="triple-card__img">
      {image.ext.toLowerCase() === ".svg" ? (
        <img src={image.publicURL} alt={header} />
      ) : (
        <Gimg fluid={image.childImageSharp.fluid} alt={header} />
      )}
    </div>
    <div className="triple-card__content">
      <h3>{header}</h3>
      <p>{body}</p>
    </div>
  </div>
)

const renderTripleCards = nodes =>
  nodes
    .map(c => c)
    .sort((a, b) => a.order - b.order)
    .slice(null, 3)
    .map(c => renderTripleCard(c))

const Triple = props => {
  const nodes = props.graphql.nodes

  return (
    <Section header="What we do">
      <div className="triple">{renderTripleCards(nodes)}</div>
    </Section>
  )
}

export default Triple
