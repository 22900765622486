import React from "react"
import Wrapper from "../../templates/Wrapper"

const LandingHero = () => {
  return (
    <Wrapper wrapperType="primary">
      <div className="hero">
        <div className="hero__1">
          <div className="hero__headline">
            Grow your business with a website and digital marketing
          </div>
          <div className="hero__buttons">
            {/* <button className="btn-primary">View portfolio</button>
            <button className="btn-secondary">Learn more</button> */}
            <button className="btn-primary">Learn more</button>
            <button className="btn-secondary">Contact us</button>
          </div>
        </div>
        <div className="hero__2" />
      </div>
    </Wrapper>
  )
}

export default LandingHero
