import React from "react"
import { graphql } from "gatsby"
import Layout from "../templates/Layout"
import Wrapper from "../templates/Wrapper"
import Hero from "../components/heroes/Landing"
import Triple from "../components/Triple"
import Benefits from "../components/Benefits"
import ContactForm from "../components/forms/Contact"

const LandingPage = ({ data }) => (
  <Layout pageTitle="landing page">
    <Hero />

    <Wrapper wrapperType="section">
      <Triple graphql={data.triple} />
    </Wrapper>

    <Wrapper wrapperType="section">
      <Benefits graphql={data.benefits} />
    </Wrapper>

    <Wrapper wrapperType="section">
      <ContactForm />
    </Wrapper>
  </Layout>
)

export const query = graphql`
  {
    triple: allStrapiBenefits(sort: { fields: order, order: ASC }, limit: 3) {
      nodes {
        id
        header
        body
        order
        hidden
        image {
          ext
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    benefits: allStrapiBenefits(sort: { fields: order, order: ASC }, skip: 3) {
      nodes {
        id
        header
        body
        order
        hidden
        image {
          ext
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default LandingPage
